import i18n from "@/libs/i18n";
import { ref, onMounted, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from '@core/utils/utils'
import useNotifications from '@/composables/useNotifications'
import useCommon from "@/views/organization/useCommon";
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import realmConnection from '@/views/habit/realm'

export default function useAssistanceNew() {
  const userData = store.state?.userStore?.userData;
  const worker_id = userData.worker_id?.$oid || null;
  const client_id = userData.client?.$oid || null;
  const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { getWorkersSupervisedForDropDown, workersSupervised, getMetadataForDropDown, metadataNotMapped, createImprovements } = useCommon()
  const { route, router } = useRouter()
  const { createItem, updateItem, getItemsWithAggregate, ObjectId } = realmConnection()
  const { improvementId } = route.value.params
  const datePickerConfig = ref({ enableTime: true, dateFormat: 'Y-m-d H:i' })
  const isSubmitting = ref(false)
  const problemContextTab = ref(null)
  const actualStateTab = ref(null)
  const rootCauseAnalysisTab = ref(null)
  const futureStateTab = ref(null)
  const actionPlanTab = ref(null)
  const followUpTab = ref(null)
  const isLoading = ref(true)
  const now = new Date()
  const startDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  const endDate = new Date(startDate)
  startDate.setHours(12, 0, 0, 0)
  endDate.setHours(12, 0, 0, 0)

  const assistance = ref({})
  const followUp = ref({ startDate, endDate, duration: 60 })
  const description = ref('')
  const problem = ref('')
  const showChat = ref(true)

  const assistanceEnd = (data) => {
    // Clean Markdown from text for form
    data.actionPlan = data.actionPlan?.replace(/\*\*/g, '')
    data.creationDate = new Date()
    showChat.value = false
    assistance.value = data
  }

  const followUpUpdate = (data) => {
    followUp.value.startDate = data.startDate
    followUp.value.endDate = data.endDate
    followUp.value.participants = data.participants
  }

  const formSubmitted = async () => {
    if (isSubmitting.value) return
    isSubmitting.value = true

    const payload = {
      ...assistance.value,
      client_id: ObjectId(client_id)
    }
    delete payload.stage
    delete payload.description
    delete payload.confirmed

    try {
      const { insertedId } = await createItem({ collection: 'assistance', payload })
      if (!insertedId) throw new Error('Item not created')

      showSuccessMessage(i18n.t('message.assistance_created'))

      // Update improvement with the assistance _id
      updateImprovementWithAssistance(insertedId)

      // Create improvements
      const newImprovementsIds = await createImprovements(improvements.value)
      
      // Create follow up event
      createFollowUpEvent(insertedId, newImprovementsIds)
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.assistance_error'))
      isSubmitting.value = false
    }
  }

  const updateImprovementWithAssistance = async (assistanceId) => {
    if (!assistanceId) return

    try {
      const query = { _id: ObjectId(improvementId) }
      const action = { $set: { assistance: assistanceId } }

      await updateItem({ collection: 'improvement', query, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_update_error') : i18n.t('message.improvement_update_error'))
    }
  }

  const createFollowUpEvent = async (assistanceId, newImprovementsIds) => {
    const payload = {
      client_id: ObjectId(client_id),
      isInstance: true,
      extendedProps: { calendar: 'Pendientes' },
      start: followUp.value.startDate instanceof Date ? followUp.value.startDate : new Date(followUp.value.startDate),
      end: followUp.value.endDate instanceof Date ? followUp.value.endDate : new Date(followUp.value.endDate),
      title: i18n.t('message.follow_up'),
      organizer: ObjectId(worker_id),
      improvements: [ObjectId(improvementId)],
      assistance: assistanceId
    }

    if (payload.end <= payload.start) {
      payload.end = new Date(payload.start)
      payload.end.setHours(payload.end.getHours() + 1)
    }

    if (followUp.value.participants?.length) {
      payload.participants = followUp.value.participants.map(e => ObjectId(e))
    }

    if (newImprovementsIds?.length) {
      payload.improvements = payload.improvements.concat(newImprovementsIds.map(e => ObjectId(e)))
    }

    try {
      const { insertedId } = await createItem({ collection: 'event', payload })

      // Redirect to calendar with follow up event open
      router.push({ name: 'apps-calendar', query: { originId: insertedId?.toString() } })
    } catch (error) {
      console.log(err)
    }
  }

  const validateTab = (tabRef) => new Promise((resolve, reject) => {
    tabRef.validate().then(success => {
      if (success) resolve(true)
      else reject()
    })
  })

  const getImprovementData = async () => {
    isLoading.value = true

    try {
      const query = { _id: ObjectId(improvementId) }

      const pipeline = [
        { $match: query },
        {
          $lookup: {
            from: 'worker',
            localField: 'assignee',
            foreignField: '_id',
            pipeline: [
              { $project: { name: 1, email: 1, locations: 1 } },
              {
                $lookup: {
                  from: 'location',
                  localField: 'locations',
                  foreignField: '_id',
                  pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                  as: 'locations',
                },
              },
              { $addFields: { _id: { $toString: "$_id" } } }
            ],
            as: 'assignee'
          }
        },
        { $addFields: { _id: { $toString: "$_id" }, assignee: { $arrayElemAt: ["$assignee", 0] }, subscribers: { $map: { input: "$subscribers", as: "id", in: { $toString: "$$id" } } } } },
      ]
    
      const items = await getItemsWithAggregate({ collection: 'improvement', pipeline })
      if (!items?.[0]) throw new Error('Item not found')
      
      const i = items[0]

      problem.value = i.note || ''
      description.value = i.description || ''
      blankImprovement.assignee = i.assignee || {}
      blankImprovement.subscribers = i.subscribers || []
      followUp.value = {
        ...followUp.value,
        participants: Array.from(new Set([ blankImprovement.assignee._id, ...blankImprovement.subscribers ]))
      }
      clearImprovementData()
    } catch (error) {
      console.log(error)
      showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_fetch_error') : i18n.t('message.improvement_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  // Add improvements
  const improvements = ref([])
  const improvementIndex = ref(-1)
  const isImprovementHandlerSidebarActive = ref(false)
  const TODO_APP_STORE_MODULE_NAME = 'app-todo'

  const { getDomains } = useCommonDashboards()
  const domains = computed(() => getDomains(client_id))

  const blankImprovement = {
    _id: null,
    client_id: { link: client_id },
    note: '',
    dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    description: '',
    assignee: null,
    subscribers: [],
    tags: [],
    completed: false,
    deleted: false,
    important: false,
    imgData: {}
  }

  const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))

  const clearImprovementData = () => {
    improvement.value = JSON.parse(JSON.stringify(blankImprovement))
    improvementIndex.value = -1
  }

  const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

  watch(metadataNotMapped, val => {
    if (val && val.length) {
      val.forEach(e => {
        // Set default creation date
        if (e.name === "creation_date") {
          e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
        }
        // Set default instance leader
        if (e.name === "instance_leader") e.answer = userData.username
      })
      blankImprovement.metadata = val
      if (!improvement.value._id) improvement.value = { ...improvement.value, metadata: val }
    }
  })

  const handleImprovementEdit = (improvementData, index) => {
    improvementIndex.value = index
    improvement.value = improvementData
    isImprovementHandlerSidebarActive.value = true
  }

  const addImprovement = (improvementData) => {
    improvements.value.push(improvementData)
  }

  const modifyImprovement = (improvementData, index) => {
    improvements.value[index] = improvementData
  }

  const removeImprovement = (index) => {
    improvements.value.splice(index, 1)
  }

  onMounted(() => {
    getImprovementData()
    getWorkersSupervisedForDropDown()
    getMetadataForDropDown({ category: "improvement", option: "notMapped" })
  })

  return {
    commitmentFunctionality,
    datePickerConfig,
    problemContextTab,
    actualStateTab,
    rootCauseAnalysisTab,
    futureStateTab,
    actionPlanTab,
    followUpTab,
    assistance,
    followUp,
    formSubmitted,
    validateTab,
    workersSupervised,
    isLoading,
    description,
    problem,
    showChat,
    assistanceEnd,
    followUpUpdate,

    // Add improvements
    improvement,
    improvements,
    handleImprovementEdit,
    removeImprovement,
    isImprovementHandlerSidebarActive,
    improvementIndex,
    clearImprovementData,
    domains,
    optionsMetadata,
    addImprovement,
    modifyImprovement,
  }
}
